import * as React from "react";
import { CSSTransition } from "react-transition-group";
import styled, { css } from "styled-components";

import {
  Col,
  Collapse,
  Container,
  MaxWidthContainer,
  Row,
  Typography,
  Spacer
} from "../../components";
import { media, HurleyThemeProps } from "../../styled";

import Header from "./HeaderStyled";
import { headerActions } from "./redux";

const DefaultLink = styled.a``;

const hover = css`
  &:hover {
    color: ${({ theme }: HurleyThemeProps) => theme.colors.named.mercury};
  }
`;
const searcHover = css`
  &:hover {
    color: ${({ theme }: HurleyThemeProps) => theme.colors.named.mercury};
  }
`;
const mobileHover = css`
  ${media.breakpoint.down("lg", hover)}
`;

const navPageIsActive = ({ current, uri = null, children = null }) => {
  if (current) {
    if (uri) {
      return (
        current
          .split("/")
          .filter($ => $)
          .join("/")
          .indexOf(
            uri
              .split("/")
              .filter($ => $)
              .join("/")
          ) === 0
      );
    }
    if (children) {
      return (
        children.filter(({ uri }) => {
          if (uri) {
            const currentParts = current.split("/").filter($ => $);
            const uriParts = (uri || "").split("/").filter($ => $);

            for (let i = 0; i < uriParts.length; i++) {
              if (uriParts[i] !== currentParts[i]) {
                return false;
              }
            }
            return true;
          } else {
            return false;
          }
        }).length > 0
      );
    }
  }
  return false;
};

export default class extends React.Component<any, any> {
  private childNode;

  constructor(props) {
    super(props);

    this.collapseOpen = this.collapseOpen.bind(this);
    this.flipNav = this.flipNav.bind(this);
    this.flipNavbar = this.flipNavbar.bind(this);
    this.killNavbar = this.killNavbar.bind(this);
    this.popNav = this.popNav.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);

    this.onDocumentMouseDown = this.onDocumentMouseDown.bind(this);
    this.setChildNodeRef = this.setChildNodeRef.bind(this);

    this.state = {
      collapses: []
    };
  }

  componentDidMount() {
    if (
      typeof document !== "undefined" &&
      this.props.header &&
      this.props.header.active
    ) {
      document.addEventListener("mousedown", this.onDocumentMouseDown, false);
    }
  }

  componentDidUpdate(props) {
    if (typeof document !== "undefined") {
      if (props.header && props.header.active) {
        document.addEventListener("mousedown", this.onDocumentMouseDown, false);
      } else {
        document.removeEventListener(
          "mousedown",
          this.onDocumentMouseDown,
          false
        );
      }
    }
  }

  componentWillUnmount() {
    if (typeof document !== "undefined") {
      document.removeEventListener(
        "mousedown",
        this.onDocumentMouseDown,
        false
      );
    }
  }

  flipNav(nav: string) {
    this.props.dispatch(headerActions.flipNav(nav));
  }

  flipNavbar() {
    this.props.dispatch(headerActions.flipNavbar());
  }

  killNavbar() {
    this.props.dispatch(headerActions.killNavbar());
  }

  navOpen(nav: string) {
    return (
      this.props.header &&
      this.props.header.navStack.filter(index => index === nav).length !== 0
    );
  }

  onDocumentMouseDown(event) {
    if (
      event.target &&
      this.childNode &&
      this.childNode.contains &&
      this.childNode.contains(event.target)
    ) {
      return;
    } else {
      this.props.dispatch(headerActions.killNavbar());
    }
  }

  popNav() {
    this.props.dispatch(headerActions.popNav());
  }

  setChildNodeRef(ref) {
    this.childNode = ref;
  }

  collapseOpen(collapse) {
    return this.state.collapses.indexOf(collapse) > -1;
  }

  toggleCollapse(collapse) {
    this.setState(state => {
      const index = state.collapses.indexOf(collapse);
      if (index > -1) {
        return {
          ...state,
          collapses: [...state.collapses.filter(item => item !== collapse)]
        };
      } else {
        return {
          ...state,
          collapses: [...state.collapses, collapse]
        };
      }
    });
  }

  render() {
    const {
      components = {},
      enableSearch = false,
      location = {},
      nav
    } = this.props;
    const active = this.props.header && this.props.header.active;

    const Link = components.Link || DefaultLink;

    return (
      <MaxWidthContainer ref={this.setChildNodeRef}>
        <Row>
          <Header>
            {nav && (nav.sites || nav.cta) && (
              <div className="desktop-site-bar">
                <div className="desktop-site-tabs">
                  {nav.sites &&
                    nav.sites.map(site => (
                      <Typography
                        className={
                          site.active
                            ? "desktop-site-tab active"
                            : "desktop-site-tab"
                        }
                        color={site.active ? null : "inherit"}
                        component="a"
                        hover="default"
                        href={site.href}
                        key={site.href}
                        onClick={this.killNavbar}
                        target={site.active ? null : "_blank"}
                        variant="button"
                      >
                        {site.title}
                      </Typography>
                    ))}
                </div>
                <div className="desktop-site-cta" onClick={this.killNavbar}>
                  {nav.cta.desktop()}
                </div>
              </div>
            )}
            <div className="navbar">
              <div className="nav-toggle" onClick={this.flipNavbar}>
                <i className={"material-icons"}>menu</i>
              </div>
              <div className="nav-logo" onClick={this.killNavbar}>
                {nav.logo()}
              </div>
              <CSSTransition
                classNames="nav"
                in={active}
                timeout={{ enter: 200, exit: 200 }}
              >
                <div className="nav">
                  <div className="nav-pop-menu">
                    <div
                      className="nav-pop-menu-action cursor-pointer"
                      onClick={this.killNavbar}
                    >
                      <i className={"material-icons"}>close</i>
                      <Typography variant="button">Close</Typography>
                    </div>
                  </div>
                  {nav && nav.cta && (
                    <div className="mobile-site-cta" onClick={this.killNavbar}>
                      {nav.cta.mobile()}
                    </div>
                  )}

                  <div className="nav-pages">
                    {nav &&
                      nav.pages &&
                      nav.pages.map((pageLvl0, index) => {
                        if (
                          Array.isArray(pageLvl0.children) &&
                          pageLvl0.children.length > 0
                        ) {
                          return (
                            <div
                              className={
                                navPageIsActive({
                                  current: location.pathname,
                                  children: pageLvl0.children
                                })
                                  ? "nav-page active"
                                  : "nav-page"
                              }
                              key={index}
                            >
                              <div
                                className="nav-page-block cursor-pointer"
                                onClick={this.flipNav.bind(this, index)}
                              >
                                <Typography
                                  className="nav-page-title"
                                  color="inherit"
                                  hover={mobileHover}
                                  variant="button"
                                >
                                  <div>
                                    {pageLvl0.icon && (
                                      <i className="material-icons desktop">
                                        {pageLvl0.icon}
                                      </i>
                                    )}
                                    {pageLvl0.title}
                                  </div>
                                  <i
                                    className={
                                      this.navOpen(index)
                                        ? "material-icons desktop active"
                                        : "material-icons desktop"
                                    }
                                  >
                                    arrow_drop_down
                                  </i>
                                </Typography>
                                <i className="material-icons mobile">
                                  chevron_right
                                </i>
                              </div>
                              <CSSTransition
                                classNames="nav-page-menu"
                                in={this.navOpen(index)}
                                timeout={{ enter: 300, exit: 200 }}
                              >
                                <div className="nav-page-menu">
                                  <Container>
                                    <Row>
                                      <div className="nav-pop-menu">
                                        <div
                                          className="nav-pop-menu-action cursor-pointer"
                                          onClick={this.popNav}
                                        >
                                          <i className={"material-icons"}>
                                            chevron_left
                                          </i>
                                          <Typography variant="button">
                                            Back
                                          </Typography>
                                        </div>
                                      </div>

                                      {pageLvl0.children.map(pageLvl1 => {
                                        const collapseOpen = this.collapseOpen(
                                          `${index}-${pageLvl1.id}`
                                        );
                                        const hasChildren =
                                          Array.isArray(pageLvl1.children) &&
                                          pageLvl1.children.length > 0;
                                        const toggleCollapse = this.toggleCollapse.bind(
                                          this,
                                          `${index}-${pageLvl1.id}`
                                        );

                                        if (pageLvl1.uri) {
                                          return (
                                            <Col
                                              lg={1 / 2}
                                              xl={1 / 3}
                                              key={pageLvl1.id}
                                            >
                                              <div className="nav-pages-block">
                                                <Typography
                                                  className="nav-page-block"
                                                  color="inherit"
                                                  component="div"
                                                  hover={hover}
                                                >
                                                  <Typography
                                                    color="inherit"
                                                    component={Link}
                                                    hover={hover}
                                                    onClick={this.killNavbar}
                                                    to={`/${pageLvl1.uri}`}
                                                    variant={"button"}
                                                  >
                                                    {pageLvl1.title}
                                                  </Typography>
                                                  <div
                                                    className={
                                                      collapseOpen
                                                        ? "nav-page-title-icon-mobile active"
                                                        : "nav-page-title-icon-mobile"
                                                    }
                                                  >
                                                    <i
                                                      className="material-icons"
                                                      onClick={toggleCollapse}
                                                    >
                                                      keyboard_arrow_down
                                                    </i>
                                                  </div>
                                                  <Typography
                                                    className="nav-page-title-icon-desktop"
                                                    color="inherit"
                                                    component={Link}
                                                    hover={hover}
                                                    onClick={this.killNavbar}
                                                    to={`/${pageLvl1.uri}`}
                                                    variant={"button"}
                                                  >
                                                    <i className="material-icons">
                                                      chevron_right
                                                    </i>
                                                  </Typography>
                                                </Typography>
                                                <Collapse
                                                  className="nav-page-collapse"
                                                  open={collapseOpen}
                                                  disableDesktop={true}
                                                >
                                                  <Row className="nav-page-columns">
                                                    {hasChildren &&
                                                      pageLvl1.children.map(
                                                        pageLvl2 => (
                                                          <Col
                                                            className="nav-page-column"
                                                            key={pageLvl2.id}
                                                          >
                                                            <Spacer margin="0 0 1rem">
                                                              <Typography
                                                                color="inherit"
                                                                component={Link}
                                                                hover={hover}
                                                                onClick={
                                                                  this
                                                                    .killNavbar
                                                                }
                                                                to={`/${pageLvl2.uri}`}
                                                                variant="body"
                                                              >
                                                                {pageLvl2.title}
                                                              </Typography>
                                                            </Spacer>
                                                          </Col>
                                                        )
                                                      )}
                                                  </Row>
                                                </Collapse>
                                              </div>
                                            </Col>
                                          );
                                        } else if (pageLvl1.href) {
                                          return (
                                            <Col
                                              lg={1 / 2}
                                              xl={1 / 3}
                                              key={pageLvl1.id}
                                            >
                                              <div className="nav-pages-block">
                                                <Typography
                                                  className="nav-page-block"
                                                  color="inherit"
                                                  component="div"
                                                  hover={hover}
                                                >
                                                  <Typography
                                                    color="inherit"
                                                    component="a"
                                                    hover={hover}
                                                    onClick={this.killNavbar}
                                                    href={pageLvl1.href}
                                                    variant={"button"}
                                                  >
                                                    {pageLvl1.title}
                                                  </Typography>
                                                  <Typography
                                                    className="nav-page-title-icon-mobile"
                                                    color="inherit"
                                                    component="a"
                                                    hover={hover}
                                                    href={pageLvl1.href}
                                                    onClick={this.killNavbar}
                                                    target="_blank"
                                                    variant={"button"}
                                                  >
                                                    <i className="material-icons">
                                                      chevron_right
                                                    </i>
                                                  </Typography>
                                                  <Typography
                                                    className="nav-page-title-icon-desktop"
                                                    color="inherit"
                                                    component="a"
                                                    hover={hover}
                                                    onClick={this.killNavbar}
                                                    href={pageLvl1.href}
                                                    target="_blank"
                                                    variant={"button"}
                                                  >
                                                    <i className="material-icons">
                                                      chevron_right
                                                    </i>
                                                  </Typography>
                                                </Typography>
                                              </div>
                                            </Col>
                                          );
                                        }
                                      })}
                                    </Row>
                                  </Container>
                                </div>
                              </CSSTransition>
                            </div>
                          );
                        } else if (pageLvl0.uri) {
                          return (
                            <div
                              className={
                                navPageIsActive({
                                  current: location.pathname,
                                  uri: pageLvl0.uri
                                })
                                  ? "nav-page active"
                                  : "nav-page"
                              }
                              key={index}
                            >
                              <Typography
                                className="nav-page-block"
                                color="inherit"
                                component={Link}
                                hover={mobileHover}
                                onClick={this.killNavbar}
                                to={`/${pageLvl0.uri}`}
                                variant="button"
                              >
                                {pageLvl0.icon && (
                                  <i className="material-icons desktop">
                                    {pageLvl0.icon}
                                  </i>
                                )}
                                {pageLvl0.title}
                              </Typography>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                  </div>

                  {nav && nav.sites && (
                    <div className="mobile-site-tabs">
                      {nav.sites.map(site => (
                        <Typography
                          className={
                            site.active
                              ? "mobile-site-tab active"
                              : "mobile-site-tab"
                          }
                          color="inherit"
                          component="a"
                          href={site.href}
                          hover={mobileHover}
                          key={site.href}
                          onClick={this.killNavbar}
                          target={site.active ? null : "_blank"}
                          variant="button"
                        >
                          {site.title}
                        </Typography>
                      ))}
                    </div>
                  )}
                </div>
              </CSSTransition>
              <div className="nav-right">
                {enableSearch && (
                  <Typography
                    className="nav-search"
                    color="default"
                    hover="default"
                    component={Link}
                    onClick={this.killNavbar}
                    to="/search"
                    variant={"button"}
                  >
                    <i className="material-icons">search</i>
                    <span className="desktop">Search</span>
                  </Typography>
                )}
              </div>
            </div>
          </Header>
        </Row>
      </MaxWidthContainer>
    );
  }
}
