import * as React from 'react'
import Form from './FormSurveyGizmoStyles'
import { Spacer, Typography } from '../../../../components'

const createScript = (ref, { surveyId, surveyName }) => {
  if (typeof document !== 'undefined') {
    const script = document.createElement('script')

    script.setAttribute('type', 'text/javascript')
    script.src = `https://www.surveygizmo.com/s3/${surveyId}/${surveyName}?__no_style=true&__output=embedjs&__ref='${escape(document.location.origin + document.location.pathname)}'`
    script.type = 'text/javascript'
    ref.appendChild(script)
  }
}

type SurveyGizmoProps = {
  header: string
  surveyId: string
  surveyName: string
}

export default class extends React.Component<SurveyGizmoProps, {}> {
  private createdScript
  private surveyRef

  constructor (props) {
    super(props)
    this.createdScript = false
    this.surveyRef = React.createRef()
  }

  componentDidMount () {
    if (
      !this.createdScript &&
      this.surveyRef.current &&
      this.props.surveyId &&
      this.props.surveyName
    ) {
      this.createdScript = true
      createScript(
        this.surveyRef.current,
        {
          surveyId: this.props.surveyId,
          surveyName: this.props.surveyName
        }
      )
    }
  }

  render () {
    if (this.props === null || typeof this.props !== 'object') {
      return null
    }

    if (this.props.surveyId && this.props.surveyName) {
      return (
        <Form>
          {
            this.props.header &&
            <Spacer padding='2rem 1rem'>
              <Typography variant='heading-2'>{this.props.header}</Typography>
            </Spacer>
          }
          <div id={`survey-wrapper-${this.props.surveyId}`} ref={this.surveyRef} />
        </Form>
      )
    }

    return null
  }
}
