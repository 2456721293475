import { selectFirst, selectLink } from '@hurleymc/sdk/selectors'
import { BroughtToYouBy, PageBuilderCalloutCard } from '@hurleymc/sdk/typings'

import * as React from 'react'
import styled, { css } from 'styled-components'

import { BroughtToYouByCaption, ChevyLink } from '../common'
import { Col, Container, Row, Spacer, Typography } from '../../../../components'
import { media, HurleyThemeProps } from '../../../../styled'
import { Redactor as OriginalRedactor } from '../common/Redactor'

interface CalloutCardProps extends PageBuilderCalloutCard {
  className?: string
  components: { Link: (props: any) => JSX.Element }
  leftBroughtToYouBy?: BroughtToYouBy[]
  rightBroughtToYouBy?: BroughtToYouBy[]
}

const Column = styled(Col)`
  color: white;
  padding: 4rem 1rem;

  ${media.breakpoint.up(
    'md',
    css`
      padding: 8rem 4rem;
    `
  )}
`

const Col1 = styled(Column)`
  background-color: #222;
`

const Col2 = styled(Column)`
  background-color: #333;
`

const Redactor = styled(OriginalRedactor)`
  a {
    border-bottom: 1px solid white;
    color: inherit;

    &:hover {
      color: ${({ theme }: HurleyThemeProps) => theme.colors.default};
      border-bottom: 1px solid ${({ theme }: HurleyThemeProps) => theme.colors.default};
    }
  }
`

export default (props: CalloutCardProps) => {
  if (props === null || typeof props !== 'object') {
    return null
  }

  const Link = props.components.Link

  const leftBtyb: BroughtToYouBy = selectFirst(props.leftBroughtToYouBy)
  const rightBtyb: BroughtToYouBy = selectFirst(props.rightBroughtToYouBy)

  const leftLink = selectLink({ linkText: props.leftLinkText, to: props.leftLink })
  const rightLink = selectLink({ linkText: props.rightLinkText, to: props.rightLink })

  return (
    <Container>
      <Row>
        <Col1 xs={1} md={1 / 2}>
          <Spacer margin='0 0 2rem 0'>
            <Typography color='inherit' variant='heading-6'>
              {props.leftIdentifier}
            </Typography>
            {props.leftBroughtToYouBy && props.leftBroughtToYouBy.length > 0 && (
              <BroughtToYouByCaption
                color='inherit'
                hover='default'
                title={leftBtyb.title}
                to={leftBtyb.to}
                component={Link}
              />
            )}
          </Spacer>
          <Spacer margin='0 0 1rem 0'>
            <Typography color='inherit' variant='heading-2'>
              {props.leftHeader}
            </Typography>
          </Spacer>
          {props.leftSummary && props.leftSummary.content && (
            <Redactor color='inherit' component='div' dangerouslySetInnerHTML={{ __html: props.leftSummary.content }} />
          )}
          <ChevyLink color='inherit' component={Link} hover='default' {...leftLink}>
            {leftLink.title}
          </ChevyLink>
        </Col1>
        <Col2 xs={1} md={1 / 2}>
          <Spacer margin='0 0 2rem 0'>
            <Typography color='inherit' variant='heading-6'>
              {props.rightIdentifier}
            </Typography>
            {props.rightBroughtToYouBy && props.rightBroughtToYouBy.length > 0 && (
              <BroughtToYouByCaption
                color='inherit'
                hover='default'
                title={rightBtyb.title}
                to={rightBtyb.to}
                component={Link}
              />
            )}
          </Spacer>
          <Spacer margin='0 0 1rem 0'>
            <Typography color='inherit' variant='heading-2'>
              {props.rightHeader}
            </Typography>
          </Spacer>
          {props.rightSummary && props.rightSummary.content && (
            <Redactor
              color='inherit'
              component='div'
              dangerouslySetInnerHTML={{ __html: props.rightSummary.content }}
            />
          )}
          <ChevyLink color='inherit' component={Link} hover='default' {...rightLink}>
            {rightLink.title}
          </ChevyLink>
        </Col2>
      </Row>
    </Container>
  )
}
