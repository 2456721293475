import { BroughtToYouBy, EntryInterface, ExternalLink } from '@hurleymc/sdk/typings'
import { selectFirst, selectLink } from '@hurleymc/sdk/selectors'
import * as React from 'react'
import styled, { css } from 'styled-components'

import { BroughtToYouByCaption, ChevyLink } from '../common'
import { Spacer, Typography } from '../../../../components'
import { media, HurleyThemeProps } from '../../../../styled'
import { Redactor as OriginalRedactor } from '../common/Redactor'

const ContentCard = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: center;
  color: white;

  ${media.breakpoint.up(
    'lg',
    css`
      min-height: 35rem;
    `
  )}
`

const Redactor = styled(OriginalRedactor)`
  a {
    border-bottom: 1px solid white;
    color: inherit;

    &:hover {
      color: ${({ theme }: HurleyThemeProps) => theme.colors.default};
      border-bottom: 1px solid ${({ theme }: HurleyThemeProps) => theme.colors.default};
    }
  }

  p {
    color: ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
  }
`

type ContentColumnProps = {
  components: { Link: (props: any) => JSX.Element };
  identifier: string;
  broughtToYouBy: BroughtToYouBy[];
  header: string;
  content: string;
  linkText: string;
  to: EntryInterface[] | ExternalLink[];
}

export default (props: ContentColumnProps) => {
  if (props === null || typeof props !== 'object') {
    return null
  }

  const btyb: BroughtToYouBy = selectFirst(props.broughtToYouBy)

  const Link = props.components.Link
  const linkProps = selectLink(props)

  return (
    <ContentCard>
      <Spacer padding={{ xs: '4rem 1rem', lg: '8rem 4rem' }}>
        <Spacer margin={'0 0 1.5rem'}>
          <Typography color='inherit' variant={'heading-6'}>
            {props.identifier}
          </Typography>
          {props.broughtToYouBy && props.broughtToYouBy.length > 0 && (
            <BroughtToYouByCaption color='inherit' hover='default' title={btyb.title} to={btyb.to} component={Link} />
          )}
        </Spacer>
        <Typography color='inherit' gutterBottom={true} variant={'heading-2'}>
          {props.header}
        </Typography>
        <Redactor
          color='inherit'
          component={'div'}
          dangerouslySetInnerHTML={{ __html: props.content }}
          paragraph={true}
        />
        <ChevyLink color='inherit' component={Link} hover='default' {...linkProps}>
          {linkProps.title}
        </ChevyLink>
      </Spacer>
    </ContentCard>
  )
}
