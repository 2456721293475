import * as React from 'react'
import styled, { css } from 'styled-components'
import {
  heading1,
  heading2,
  heading3,
  heading4,
  heading5,
  heading6,
  body,
  body2,
  button,
  caption,
  quote,
  pullQuote
} from './TypographyPresets'
import { HurleyThemeProps } from '../../styled'

const TypographyComponent: any = ({ component, variant = 'body', textTransform, ...props }) => {
  if (props.gutterBottom) {
    delete props.gutterBottom
  }

  if (props.paragraph) {
    delete props.paragraph
  }

  switch (variant) {
    case 'display':
    case 'heading-1':
      return React.createElement(component || 'h1', { ...props })
    case 'heading-2':
      return React.createElement(component || 'h2', { ...props })
    case 'heading-3':
      return React.createElement(component || 'h3', { ...props })
    case 'heading-4':
      return React.createElement(component || 'h4', { ...props })
    case 'heading-5':
      return React.createElement(component || 'h5', { ...props })
    case 'heading-6':
      return React.createElement(component || 'h6', { ...props })
    case 'button':
    case 'caption':
      return React.createElement(component || 'span', { ...props })
    case 'body':
      return React.createElement(component || 'p', { ...props })
    case 'body2':
      return React.createElement(component || 'aside', { ...props })
    case 'pull-quote':
    case 'quote':
      return React.createElement(component || 'blockquote', { ...props })
    case 'redactor':
      return React.createElement(component || 'div', { ...props })
    default:
      console.warn(`Unknown Typography variant: ${variant}.`)
      return React.createElement(component || 'p', { ...props })
  }
}

export default styled(TypographyComponent)`
  font-size: 1rem;
  margin: 0;

  /* TODO(): remove font-smoothing */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  ${({ variant = 'body' }: any) => {
    switch (variant) {
      case 'body2':
        return body2
      case 'button':
        return button
      case 'heading-1':
        return heading1
      case 'heading-2':
        return heading2
      case 'heading-3':
        return heading3
      case 'heading-4':
        return heading4
      case 'heading-5':
        return heading5
      case 'heading-6':
        return heading6
      case 'caption':
        return caption
      case 'quote':
        return quote
      case 'pull-quote':
        return pullQuote
      case 'body':
      default:
        return body
    }
  }}
  ${({ color = null }: any) => {
    switch (color) {
      case 'inherit':
        return css`
          color: inherit;
        `
      case 'default':
        return css`
          color: ${({ theme }: HurleyThemeProps) => theme.colors.default};
        `
      case 'primary':
        return css`
          color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
        `
      case 'dark':
        return css`
          color: ${({ theme }: HurleyThemeProps) => theme.colors.dark};
        `
      case 'light':
        return css`
          color: ${({ theme }: HurleyThemeProps) => theme.colors.light};
        `
      default:
        return color
    }
  }}
  ${({ hover = null }: any) => {
    switch (hover) {
      case 'inherit':
        return css`
          &:hover {
            color: inherit;
          }
        `
      case 'default':
        return css`
          &:hover {
            color: ${({ theme }: HurleyThemeProps) => theme.colors.default};
          }
        `
      case 'primary':
        return css`
          &:hover {
            color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
          }
        `
      case 'dark':
        return css`
          &:hover {
            color: ${({ theme }: HurleyThemeProps) => theme.colors.dark};
          }
        `
      case 'light':
        return css`
          &:hover {
            color: ${({ theme }: HurleyThemeProps) => theme.colors.light};
          }
        `
      default:
        return hover
    }
  }}
  ${({ color = false }: any) =>
    color
      ? css`
          color: ${color};
        `
      : null}
  ${({ gutterBottom = false }: any) =>
    gutterBottom
      ? css`
          margin-bottom: 0.5em;
        `
      : null}
  ${({ paragraph = false }: any) =>
    paragraph
      ? css`
          margin-bottom: 1rem;
        `
      : null}
  ${({ textTransform = null }: any) =>
    textTransform
      ? css`
          text-transform: ${textTransform};
        `
      : null}
`
