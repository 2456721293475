import * as React from 'react'
import YouTube from 'react-youtube'
import styled from 'styled-components'

import { AspectRatio } from '../../../../components'

const YouTubeBox: any = styled(AspectRatio)`
  .youtube-container {
    height: 100%;
  }
  iframe {
    height: 100%;
    width: 100%;
  }
`

export default class extends React.Component<any, any> {
  constructor (props) {
    super(props)

    this.state = { playing: false }

    // this.onReady = this.onReady.bind(this)
    this.onPlay = this.onPlay.bind(this)
    this.onPause = this.onPause.bind(this)
    this.onEnd = this.onEnd.bind(this)
    this.onError = this.onError.bind(this)
    // this.onStateChange = this.onStateChange.bind(this)
    // this.onPlaybackRateChange = this.onPlaybackRateChange.bind(this)
    // this.onPlaybackQualityChange = this.onPlaybackQualityChange.bind(this)
  }

  // onReady () {}
  onPlay () {
    this.setState({ playing: true })
  }

  onPause () {
    this.setState({ playing: false })
  }

  onEnd () {
    this.setState({ playing: false })
  }

  onError () {
    this.setState({ playing: false })
  }

  // onStateChange () {}
  // onPlaybackRateChange () {}
  // onPlaybackQualityChange () {}

  render () {
    return (
      <YouTubeBox ratio='16:9' active={this.state.playing}>
        <YouTube
          containerClassName='youtube-container'
          videoId={this.props.videoId}
          opts={{ color: 'white' }}
          // onReady={this.onReady}
          onPlay={this.onPlay}
          onPause={this.onPause}
          onEnd={this.onEnd}
          onError={this.onError}
          // onStateChange={this.onStateChange}
          // onPlaybackRateChange={this.onPlaybackRateChange}
          // onPlaybackQualityChange={this.onPlaybackQualityChange}
        />
      </YouTubeBox>
    )
  }
}
