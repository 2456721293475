import { css } from "styled-components";
import { media, HurleyThemeProps } from "../../../styled";

export default css`
  .navbar {
    align-items: center;
    background-color: white;
    border-bottom: 1px solid
      ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .cursor-pointer {
    &:hover {
      cursor: pointer;
    }
  }

  .material-icons {
    display: block;
    line-height: 1;
  }

  /* nav menu toggle */
  .nav-toggle {
    line-height: 0;
    padding: 1rem;
  }

  ${media.breakpoint.up(
    "lg",
    css`
      .nav-toggle {
        display: none;
      }
    `
  )}

  /* logo portal */
  .nav-logo {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 5rem;
    padding: 1rem;
  }

  ${media.breakpoint.up(
    "sm",
    css`
      .nav-logo {
        height: 6rem;
      }
    `
  )}
  ${media.breakpoint.up(
    "md",
    css`
      .nav-logo {
        height: 7rem;
      }
    `
  )}

  .nav-right {
    flex: 1;
  }

  .nav-search {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 1rem;

    .desktop {
      display: none;
    }

    .material-icons {
      color: #222;
    }
  }

  ${media.breakpoint.up(
    "lg",
    css`
      .nav-search {
        .desktop {
          display: inherit;
        }

        .material-icons {
          color: inherit;
          margin-right: 0.5rem;
        }
      }
    `
  )}

  /* mobile cta portal */
  .mobile-site-cta {
    padding: 0 1rem;
  }

  ${media.breakpoint.up(
    "lg",
    css`
      .mobile-site-cta {
        display: none;
      }
    `
  )}

  /* mobile site tabs */
  .mobile-site-tabs {
    border-top: 1px solid rgba(255, 255, 255, 0.625);
    color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 2rem 0;
    padding: 2rem 1rem 0;
  }

  ${media.breakpoint.up(
    "lg",
    css`
      .mobile-site-tabs {
        display: none;
      }
    `
  )}

  .mobile-site-tab {
    margin: 0.25rem 0;
    padding: 0.5rem 0;
  }
`;
