module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hurley Medical Education","short_name":"Hurley Medical Education","start_url":"/","background_color":"#f7f0eb","theme_color":"#E08700","display":"standalone","icon":"src/images/hurley-medical-education-favicon.png"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MTGSZV5","gtmAuth":"UgmSEfhaGTLsZ3M28aK0gg","gtmPreview":"env-6"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
