// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-person-tsx": () => import("./../src/templates/person.tsx" /* webpackChunkName: "component---src-templates-person-tsx" */),
  "component---src-templates-home-tsx": () => import("./../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-page-basic-page-tsx": () => import("./../src/templates/page-basic-page.tsx" /* webpackChunkName: "component---src-templates-page-basic-page-tsx" */),
  "component---src-templates-page-story-with-summary-tsx": () => import("./../src/templates/page-story-with-summary.tsx" /* webpackChunkName: "component---src-templates-page-story-with-summary-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-listing-tsx": () => import("./../src/templates/blog-listing.tsx" /* webpackChunkName: "component---src-templates-blog-listing-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

