import { css } from 'styled-components'
import { media, HurleyThemeProps } from '../../../styled'

const speed = '200ms'

export default css`
 .nav {
    align-self: stretch;
    background-color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
    bottom: 0;
    display: block;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    left: 0;
    max-width: 420px;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    width: 100%;

    &.nav-enter {
      transform: translateX(-100%);
    }

    &.nav-enter.nav-enter-active {
      transform: translateX(0%);
      transition: transform ${speed} ease-in;
    }

    &.nav-enter-done {
      overflow: scroll;
      transform: translateX(0%);
    }

    &.nav-exit {
      transform: translateX(0%);
    }

    &.nav-exit.nav-exit-active {
      transform: translateX(-100%);
      transition: transform ${speed} ease-out;
    }
  }

  ${media.breakpoint.up(
    'lg',
    css`
      .nav {
        background-color: transparent;
        display: flex;
        flex-direction: row;
        left: unset;
        max-width: unset;
        padding: 0;
        position: static;
        right: unset;
        top: unset;
        width: auto;
        transform: none;
        transition: unset;

        &.nav-enter {
          transform: none;
        }

        &.nav-enter.nav-enter-active {
          transform: none;
          transition: unset;
        }

        &.nav-enter-done {
          overflow: auto;
          transform: none;
        }

        &.nav-exit {
          transform: none;
        }

        &.nav-exit.nav-exit-active {
          transform: none;
          transition: unset;
        }
      }
    `
  )}

  .nav-pages {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0 1rem;
  }


  ${media.breakpoint.up(
    'lg',
    css`
      .nav-pages {
        flex-direction: row;
        padding: 0;
      }
    `
  )}

  .nav-pages-block {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0 .5rem;
  }

  .nav-page {
    color: white;
  }

  ${media.breakpoint.up(
    'lg',
    css`
      .nav-page {
        align-items: center;
        border-bottom: 4px solid transparent;
        color: inherit;
        display: flex;
        margin: 0 0.75rem;

        &.active {
          border-bottom: 4px solid black;
        }
      }
    `
  )}

  .nav-page-block {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: .25rem 0;
    padding: .5rem 0;

    .material-icons.desktop {
      display: none;
    }
  }

  ${media.breakpoint.up(
    'lg',
    css`
      .nav-page-block {
        text-align: center;

        .material-icons.mobile {
          display: none;
        }

        .material-icons.desktop {
          display: inherit;
          transform: rotateX(0deg);
          transform-style: preserve-3d;
          transition: transform ${speed} ease-out;

          &.active {
            transform: rotateX(180deg);
            transform-style: preserve-3d;
            transition: transform ${speed} ease-in;
          }
        }
      }
    `
  )}

  ${media.breakpoint.up(
    'lg',
    css`
      .nav-page-title {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
      }
    `
  )}

  .nav-page-title-icon-mobile {
    display: inline-block;
    position: relative;

    .material-icons {
      transform: rotateX(0deg);
      transform-style: preserve-3d;
      transition: transform ${speed} ease-out;
    }

    &.active {
      .material-icons {
        transform: rotateX(180deg);
        transform-style: preserve-3d;
        transition: transform ${speed} ease-in;
      }
    }
  }

  ${media.breakpoint.up(
    'lg',
    css`
      .nav-page-title-icon-mobile {
        display: none;
      }
    `
  )}

  .nav-page-title-icon-desktop {
    display: none;
  }

  ${media.breakpoint.up(
    'lg',
    css`
      .nav-page-title-icon-desktop {
        display: inline-block;
      }
    `
  )}

  ${media.breakpoint.up(
    'lg',
    css`
      .nav-page-collapse {
        border-top: 1px solid white;
        padding-top: 1rem;
      }
    `
  )}

  .nav-page-menu {
    background-color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
    bottom: 0;
    color: white;
    display: block;
    left: 0;
    max-width: 420px;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    width: 100%;

    &.nav-page-menu-enter.nav-page-menu-enter-active {
      transform: translateX(0%);
      transition: transform ${speed} ease-in;
    }

    &.nav-page-menu-enter-done {
      overflow: scroll;
      transform: translateX(0%);
    }

    &.nav-page-menu-exit {
      transform: translateY(0%);
      transition: transform ${speed} ease-in;
    }

    &.nav-page-menu-exit.nav-page-menu-exit-active {
      transform: translateX(-100%);
    }
  }

  ${media.breakpoint.up(
    'lg',
    css`
      .nav-page-menu {
        background-color: ${({ theme }: HurleyThemeProps) => theme.colors.named.charcoal};
        bottom: unset;
        display: none;
        max-width: unset;
        opacity: 0;
        overflow: hidden;
        padding-bottom: 1rem;
        padding-top: 1rem;
        position: absolute;
        right: 0;
        top: 100%;
        transform: translateY(-15px);
        z-index: -1;

        &.nav-page-menu-enter {
          display: block;
          opacity: 0;
          transform: translateY(-15px);
        }

        &.nav-page-menu-enter.nav-page-menu-enter-active {
          display: block;
          opacity: 1;
          transform: translateY(0px);
          transition: opacity ${speed} ease-in 100ms, transform ${speed} ease-in 100ms;
        }

        &.nav-page-menu-enter-done {
          display: block;
          opacity: 1;
          transform: translateY(0px);
        }

        &.nav-page-menu-exit {
          display: block;
          opacity: 1;
          transform: translateY(0px);
        }

        &.nav-page-menu-exit.nav-page-menu-exit-active {
          display: block;
          opacity: 0;
          transform: translateY(-15px);
          transition: opacity ${speed} ease-out, transform ${speed} ease-out;
        }

        &.nav-page-menu-exit-done {
          opacity: 0;
          transform: translateY(-15px);
        }
      }
    `
  )}

  ${media.breakpoint.up(
    'lg',
    css`
      .nav-page-columns {
        column-count: 2;
        column-gap: 0;
        display: block;
        orphans: 1;
        widows: 1;
      }

      .nav-page-column {
        display: inline-block;
        flex: unset;
        orphans: 1;
        widows: 1;
        width: 100%;
      }
    `
  )}


  .nav-pop-menu {
    align-items: center;
    background-color: white;
    color: #222;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 5rem;
    margin-bottom: 1rem;
    width: 100%;
  }

  ${media.breakpoint.up(
    'sm',
    css`
      .nav-pop-menu {
        height: 6rem;
      }
    `
  )}
  ${media.breakpoint.up(
    'md',
    css`
      .nav-pop-menu {
        height: 7rem;
      }
    `
  )}
  ${media.breakpoint.up(
    'lg',
    css`
      .nav-pop-menu {
        display: none;
      }
    `
  )}

  .nav-pop-menu-action {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    line-height: 0;
    padding: 0 .5rem;

    .material-icons {
      margin-right: .5rem;
    }
  }

`
