import { selectLink } from '@hurleymc/sdk/selectors'
import { PageBuilderVideo, VideoYouTube } from '@hurleymc/sdk/typings'
import * as React from 'react'
import YouTube from './YouTube'
import styled from 'styled-components'

import { ChevyLink } from '../common'
import { Col, Container, Row, Spacer, Typography } from '../../../../components'

const Flexbox = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  height: 100%;
`

interface VideoType extends VideoYouTube {
  __typename: 'VideoYouTube'
}

const makeVideo = (video: VideoType) => {
  switch (video.__typename) {
    case 'VideoYouTube':
      return (<YouTube videoId={video.videoId} />)
    default:
      return null
  }
}

interface VideoProps extends PageBuilderVideo {
  className?: string
  components: { Link: (props: any) => JSX.Element },
  video: VideoYouTube[]
}

export default (props: VideoProps) => {
  if (props === null || typeof props !== 'object') {
    return null
  }

  const Link = props.components.Link
  const linkProps = selectLink(props)

  return (
    <Spacer margin={{ xs: '6rem .5rem', lg: '8rem .5rem' }}>
      <Container>
        <Row>
          <Col md={7 / 12}>
            {
              props.video && props.video.length > 0 &&
              <Spacer margin={{ xs: '0 0 2rem', md: '0' }}>{makeVideo(props.video[0] as any)}</Spacer>
            }
          </Col>
          <Col md={5 / 12}>
            <Flexbox>
            {
              props.identifier &&
              <Spacer margin='0 0 1rem'>
                <Typography variant='heading-5'>{props.identifier}</Typography>
              </Spacer>
            }
            {
              props.header &&
              <Spacer margin='0 0 1rem'>
                <Typography variant='heading-2'>{props.header}</Typography>
              </Spacer>
            }
            {
              props.summary && props.summary.content &&
              <Typography component='div' dangerouslySetInnerHTML={{ __html: props.summary.content }} variant='body' />
            }
            <ChevyLink color='default' component={Link} {...linkProps}>{linkProps.title}</ChevyLink>
            </Flexbox>
          </Col>
        </Row>
      </Container>
    </Spacer>
  )
}
